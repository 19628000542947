import { useState, useContext, useCallback, useEffect } from "react";
import { DAppContext } from "./context";
import { Toaster } from "react-hot-toast";
import Swiper from "swiper/bundle";
import { ReactComponent as Opensea } from "./opensea.svg";
import { ReactComponent as Twitter } from "./twitter.svg";
import BannerRight from "./banner_right.png";
import Slide1 from "./slide_1.png";
import Slide2 from "./slide_2.png";
import Slide3 from "./slide_3.png";
import Slide4 from "./slide_4.png";
import Slide5 from "./slide_5.png";
import Slide6 from "./slide_6.png";
import Slide7 from "./slide_7.png";
import Slide8 from "./slide_8.png";
import Slide9 from "./slide_9.png";
import Slide10 from "./slide_10.png";
import Slide11 from "./slide_11.png";
import Slide12 from "./slide_12.png";
import Slide13 from "./bull_1.png";
import Slide14 from "./bull_2.png";
import Slide15 from "./bull_3.png";
import Slide16 from "./bull_4.png";
import Slide17 from "./bull_5.png";
import Bull from "./banner_bull.png";
import Bull2 from "./bull_banner.png";
import "swiper/css/bundle";
import "bootstrap/dist/css/bootstrap.css";

function App() {
  const [count, setCount] = useState(3);
  const [mintedCount, setMintedCount] = useState(0);
  const {
    connectBrowserWallet,
    mint,
    userData,
    transactionHash,
    contractDetails,
  } = useContext(DAppContext);

  const handleCounter = (e) => {
    const { dataset } = e.currentTarget;
    const { pos } = dataset;

    if (pos === "plus" && count + 1 <= 25) {
      setCount(() => count + 1);
      return;
    }

    if (count - 1 !== 0 && pos === "minus") {
      setCount(() => count - 1);
    }
  };

  const handleCustomMint = useCallback(() => {
    mint(count);
  }, [count, userData]);

  useEffect(() => {
    if (!!contractDetails) {
      setMintedCount(parseInt(Number(contractDetails.alreadyMinted)));
    }
  }, [contractDetails]);

  useEffect(() => {
    const swiper = new Swiper(".about-slider", {
      spaceBetween: 70,
      slidesPerView: 1,
      grabCursor: true,
      mousewheelControl: true,
      keyboardControl: true,
      autoplay: {
        delay: 2500,
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
        568: {
          slidesPerView: 2,
        }
      }
    });
    const swiper2 = new Swiper(".about-slider-2", {
      spaceBetween: 70,
      slidesPerView: 1,
      loop: true,
      grabCursor: true,
      mousewheelControl: true,
      keyboardControl: true,
      autoplay: {
        delay: 2500,
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
        },
        568: {
          slidesPerView: 2,
        }
      }
    });
  }, []);

  return (
    <>
      <header className="banner-container">
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-12">
              <h1>
                Yet the story of <br /> Bully Bears
              </h1>
              <p className="mb-5">
                Ola, you little scared Bulls! <br />
                Bully Bears are in the house! <br />
                I can assure you that you won't find a stronger ally to face
                me...
                <br />
                So I'd give you just one chance, join me or you'll be the next.
                <br />
                With love and pressure, your Bully Bears
                <br />
                <b>First 2500 Bully Bears FREE max 3</b> 
                <br />    <br />
                <b>After 2500 BBs, price cheap as gas 0.004</b> 
              </p>
              <div className="mint-container">
                {!!userData ? (
                  <>
                  <div className="bear-form-counter">
                    <button
                      onClick={handleCounter}
                      type="button"
                      className="bear-btn"
                      data-pos="minus"
                    >
                      -
                    </button>
                    <span> {count} </span>
                    <button
                      onClick={handleCounter}
                      type="button"
                      className="bear-btn"
                      data-pos="plus"
                    >
                      +
                    </button>
                    <button
                      onClick={handleCustomMint}
                      type="button"
                      className="claim-btn"
                    >
                      Mint
                    </button>
                  </div>
                  <div className="vampire-claimers">
                    <button
                      onClick={() => mint(5)}
                      type="button"
                      className="claim-btn"
                    >
                      Mint 5
                    </button>
                    <button
                      onClick={() => mint(15)}
                      type="button"
                      className="claim-btn"
                    >
                      Mint 15
                    </button>
                    <button
                      onClick={() => mint(25)}
                      type="button"
                      className="claim-btn"
                    >
                      Mint 25
                    </button>
                  </div>
                  {transactionHash && (
                    <a
                      href={`https://etherscan.io/tx/${transactionHash}`}
                      target="_blank"
                      rel="noreferrer"
                      className="claim-btn d-flex align-items-center"
                    >
                      See transaction
                    </a>
                  )}
                </>
                ) : (
                  <button
                    onClick={connectBrowserWallet}
                    type="button"
                    className="btn btn-primary"
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            </div>
            <div className="col-xl-7 col-12 position-relative order-mobile">
              <img src={BannerRight} alt="" />
              <h4 className="banenr_text text-uppercase">bully bears gang</h4>
            </div>
          </div>
        </div>
        <div className="socials d-flex align-items-center gap-3 mb-3">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/bullybear18"
            className="social-btn"
          >
            <Twitter />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://opensea.io/collection/bully-bears-official"
            className="social-btn social-opensea"
          >
            <Opensea />
          </a>
        </div>
      </header>
      <div className="container mb-5 pb-5">
        <div className="row about">
          <div>
            <div className="swiper about-slider">
              <div className="swiper-wrapper">
                <div className="swiper-slide about-slider-item">
                  <img src={Slide1} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide2} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide3} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide4} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide5} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide6} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide7} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide8} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide9} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide10} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide11} alt="" />
                </div>
                <div className="swiper-slide about-slider-item">
                  <img src={Slide12} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12 new-collection">
            <h2 className="new-collection-title">Contribution</h2>
            <div className="banner_for_new_col">
              <img src={Bull} alt="" />
              <div>
                <h3>Remember these guys?</h3>
                <p>
                  Our Bears just beat the sh#t out of them. <br />
                  This is exactly what ypu can see on the crypto Market right
                  now
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 prev-collection mt-5">
            <div className="banner_for_prev_col">
              <div>
                <h3>Get yourself a loser bull</h3>
                <p>
                  we are preparing an amazing BONUS for those who minted items
                  both from Loser Bulls and Bully Bears
                </p>
              </div>
              <img src={Bull2} alt="" />
            </div>
          </div>
          <div className="col-12 mt-5 pt-5">
            <div>
              <div className="swiper about-slider-2">
                <div className="swiper-wrapper">
                  <div className="swiper-slide about-slider-item">
                    <img src={Slide13} alt="" />
                  </div>
                  <div className="swiper-slide about-slider-item">
                    <img src={Slide14} alt="" />
                  </div>
                  <div className="swiper-slide about-slider-item">
                    <img src={Slide15} alt="" />
                  </div>
                  <div className="swiper-slide about-slider-item">
                    <img src={Slide16} alt="" />
                  </div>
                  <div className="swiper-slide about-slider-item">
                    <img src={Slide17} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 connect-btns my-5 p5-5 d-flex align-items-center justify-content-center flex-wrap gap-3">
            <h2 className="col-12 text-uppercase text-center mb-2">Let’s Connect</h2>
            <p className="col-12 text-uppercase text-center mb-3">Get in touch for opportunities or just to say hi! 👋</p>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/bullybear18"
              className="social-btn"
            >
              <Twitter />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="#"
              className="social-btn social-opensea"
            >
              <Opensea />
            </a>
          </div>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={false} />
    </>
  );
}

export default App;
